import type { SiteConfig } from "../site.tsx";

const SyncSessionConfig: SiteConfig = {
  company: {
    name: "Sync Session",
    tagline: "Revolutionizing music collaboration through ultra-low-latency mesh networking for real-time jamming and recording.",
    description: "Pioneer in mesh-networked audio/video bridging for music studios.\nDelivering sub-millisecond latency for real-time collaboration.\nEnabling distributed recording sessions across multiple locations.\nCreating next-generation tools for remote music production.",
    details: "Sync Session is transforming the music industry through revolutionary low-latency networking technology that enables seamless real-time collaboration between musicians, producers, and engineers across multiple locations. Built on the ZeroServer mesh framework, our platform delivers unprecedented audio and video synchronization that makes remote collaboration feel like being in the same room.\n\nOur innovative mesh networking solution creates virtual bridges between recording studios, rehearsal spaces, and home setups, enabling musicians to jam, record, and produce together with imperceptible latency. By leveraging edge computing and sophisticated audio/video synchronization algorithms, we've achieved sub-millisecond latency that maintains perfect timing across distributed sessions.\n\nThe platform's distributed architecture ensures optimal routing of audio and video streams, automatically adapting to network conditions to maintain consistent low-latency performance. Our integration with professional audio interfaces and studio equipment enables full multi-track recording capabilities across distributed locations, revolutionizing the recording process for the digital age.\n\nThrough our comprehensive suite of collaboration tools, we're enabling new workflows for remote music production, virtual rehearsals, and distributed recording sessions. Features like virtual control rooms, multi-location mixing, and synchronized playback are creating unprecedented opportunities for musical collaboration and creativity.",
    offerings: [
      {
        type: "product",
        name: "StudioBridge",
        description: "Ultra-low-latency audio/video bridging platform for distributed music collaboration",
        features: [
          "Sub-millisecond latency networking",
          "Multi-track recording across locations",
          "Professional audio interface integration",
          "Adaptive mesh routing"
        ],
        benefits: [
          "Seamless remote collaboration",
          "Professional audio quality",
          "Reduced travel needs",
          "Expanded creative possibilities"
        ]
      },
      {
        type: "service",
        name: "Virtual Studio Network",
        description: "Managed network of connected recording spaces and rehearsal rooms",
        features: [
          "Studio-to-studio bridging",
          "Virtual control room",
          "Multi-location mixing",
          "Synchronized playback"
        ],
        benefits: [
          "Access to global talent",
          "Enhanced productivity",
          "Flexible collaboration",
          "Cost-effective production"
        ]
      }
    ],
    values: [
      "Technical Excellence",
      "Musical Innovation",
      "Collaborative Creation",
      "Professional Quality"
    ],
    beliefs: [
      "Music transcends physical distance",
      "Technology should enable creativity",
      "Collaboration drives innovation",
      "Quality is non-negotiable"
    ],
    norms: [
      "Zero-compromise audio",
      "Reliability first",
      "Creative enablement",
      "Professional standards"
    ],
    historical_narrative: [
      "Founded in 2023 to revolutionize remote music collaboration",
      "Developed initial mesh networking prototype for audio/video synchronization",
      "Achieved breakthrough sub-millisecond latency for distributed music sessions",
      "Established first inter-studio virtual bridge network",
      "Launched beta platform with professional recording studios",
      "Integrated with major professional audio interfaces and equipment",
      "Expanded platform to support home studio setups",
      "Partnered with ZeroServer for enhanced mesh networking capabilities"
    ],
    mission: [
      "To eliminate geographical barriers in music collaboration through innovative technology",
      "To enable seamless real-time musical interaction across any distance",
      "To maintain professional audio quality in remote collaboration",
      "To revolutionize the music production process for the digital age"
    ],
    image_prompts: [
      "Modern recording studio with multiple holographic displays showing connected remote musicians in real-time collaboration",
      "Technical visualization of ultra-low-latency mesh network connecting multiple studio spaces with audio waveforms flowing between nodes",
      "Split-screen view of multiple recording studios connected through virtual bridges, showing synchronized session activity",
      "Professional mixing console integrated with virtual control room displays showing multi-location session management",
      "Abstract representation of sub-millisecond audio synchronization across distributed recording spaces",
      "Clean, modern interface showing real-time latency metrics and network optimization for studio connections",
      "Artistic visualization of musicians collaborating across virtual space with pristine audio wavelengths connecting them",
      "Isometric illustration of the StudioBridge platform architecture showing mesh network topology and audio routing",
      "Dynamic 3D representation of multiple rehearsal spaces connected through low-latency virtual bridges",
      "Professional studio environment with integrated displays showing remote musician feeds and session controls"
    ]
  },
  theme: {
    primary: "#2C3E50",    // Deep blue-gray
    secondary: "#E74C3C",  // Vibrant red
    accent: "#3498DB",     // Bright blue
    background: "#ECF0F1", // Light gray
    text: "#2C3E50"        // Dark blue-gray
  },
  navigation: {
    showTechnology: true,
    showSolutions: true,
    showBlog: false,
    showNews: true,
    showHelp: true,
    showAuth: true
  },
  footerLinks: [
    { label: "About", href: "/about" },
    { label: "Technology", href: "/technology" },
    { label: "Solutions", href: "/solutions" },
    { label: "Contact", href: "/contact" }
  ],
  aboutPage: {
    title: "Revolutionizing Music Collaboration",
    subtitle: "Breaking down geographical barriers with ultra-low-latency networking",
    mission: {
      title: "Our Mission",
      content: "To transform the music industry by enabling seamless real-time collaboration across any distance, maintaining professional audio quality and revolutionizing the production process for the digital age."
    },
    team: [
      {
        name: "Sarah Chen",
        role: "Chief Technology Officer",
        background: "Former lead architect at major audio software company with 15 years experience in low-latency networking"
      },
      {
        name: "Marcus Rodriguez",
        role: "Head of Audio Engineering",
        background: "Grammy-winning producer and audio engineer with expertise in distributed recording systems"
      }
    ],
    values: [
      {
        title: "Technical Excellence",
        description: "Pushing the boundaries of what's possible in networked audio collaboration"
      },
      {
        title: "Musical Innovation",
        description: "Creating new possibilities for creative collaboration in music"
      }
    ]
  },
  technologyPage: {
    title: "Our Technology",
    subtitle: "Breaking the Barriers of Distance",
    markdown: "# Revolutionary Low-Latency Networking\n\nOur proprietary mesh networking technology enables true real-time collaboration across any distance, with sub-millisecond latency that maintains perfect synchronization between multiple locations."
  },
  solutionsPage: {
    title: "Solutions",
    subtitle: "Professional Tools for Remote Collaboration",
    content: "Discover our suite of professional-grade solutions for remote music production and collaboration."
  },
  homePage: {
    hero: {
      title: "The Future of Music Collaboration",
      highlight: "Sub-millisecond latency for real-time remote sessions",
      description: "Connect studios and musicians worldwide with professional-grade audio quality and unprecedented synchronization"
    },
    config: {
      showPromoVideo: true,
      showContactForm: true
    }
  },
  media: {
    promoVideo: "https://syncsession.com/media/promo.mp4",
    promoVideoPoster: "https://syncsession.com/media/promo-poster.jpg"
  }
};

export default SyncSessionConfig;
