import type { SiteConfig } from "../site.tsx";

const GKFConfig: SiteConfig = {
  company: {
    name: "GateKeeper.Family",
    tagline: "Unifying family care services through AI-powered coordination and intelligent caregiving solutions.",
    description: "Pioneering AI-driven family care coordination platform.\nSpecializing in comprehensive caregiver management solutions.\nDelivering intelligent conversation systems for family care oversight.\nEnabling seamless integration of family care services.",
    details: "GateKeeper.Family (GKF) is revolutionizing family care management through an innovative AI-powered platform that serves as a central coordination point for all family care services. Our platform uniquely addresses the complex challenges of managing care for both elderly family members and children, providing a unified solution for families juggling multiple caregiving responsibilities.\n\nOur flagship product, \"Nanny,\" represents a breakthrough in caregiver staffing and operations management. The system combines sophisticated AI-driven conversation capabilities with practical caregiving coordination tools to ensure seamless daily operations. This intelligent platform maintains continuous oversight of caregiving activities, coordinating schedules, tracking tasks, and providing real-time updates to family members.\n\nThrough our conversational AI system, GateKeeper.Family maintains constant communication with caregivers, family members, and care recipients, ensuring that daily plans are executed properly and any deviations are immediately addressed. The platform's intelligent monitoring capabilities provide families with peace of mind while maintaining the dignity and independence of care recipients.\n\nThe platform serves as a comprehensive family care aggregation point, integrating various services including caregiver scheduling, daily activity monitoring, emergency response coordination, and family communication. This unified approach simplifies the complex task of managing multiple care relationships while ensuring high-quality care delivery for all family members.",
    offerings: [
      {
        type: "product",
        name: "Nanny",
        description: "AI-powered caregiver management and family coordination platform",
        features: [
          "Intelligent caregiver staffing",
          "Real-time activity monitoring",
          "AI conversational oversight",
          "Family communication hub"
        ],
        benefits: [
          "Seamless care coordination",
          "Enhanced family peace of mind",
          "Improved care quality",
          "Reduced management overhead"
        ],
        value_prop: [
          "Simplify complex care coordination through AI-powered management",
          "Ensure consistent high-quality care through intelligent oversight",
          "Reduce family stress with comprehensive care monitoring",
          "Maintain independence and dignity for care recipients"
        ]
      },
      {
        type: "service",
        name: "Family Care Coordination",
        description: "Comprehensive family care service integration and management",
        features: [
          "Multi-service aggregation",
          "Care schedule optimization",
          "Emergency response coordination",
          "Family status reporting"
        ],
        benefits: [
          "Unified care management",
          "Simplified family coordination",
          "Enhanced care oversight",
          "Better family communication"
        ],
        value_prop: [
          "Centralize all family care services in one intelligent platform",
          "Optimize care schedules and resource allocation",
          "Ensure rapid response to care needs and emergencies",
          "Improve family communication and coordination"
        ]
      }
    ],
    values: [
      "Family Well-being",
      "Care Excellence",
      "Technological Innovation",
      "Service Integration"
    ],
    beliefs: [
      "Families deserve seamless care coordination",
      "AI can enhance caregiving quality",
      "Communication is key to care success",
      "Integrated services improve outcomes"
    ],
    norms: [
      "Family-first approach",
      "Continuous care monitoring",
      "Proactive communication",
      "Service excellence"
    ],
    image_prompts: [
      "Warm, inviting interface showing family care coordination dashboard with real-time updates and status indicators",
      "Professional caregiver using mobile app to log activities and communicate with family members",
      "Split-screen visualization of AI monitoring system showing both child and elderly care coordination",
      "Modern family home setting with subtle technology integration for care monitoring",
      "Caring interaction between caregiver and elderly person with discrete technology support",
      "Family members reviewing care updates on mobile devices with intuitive interface",
      "Emergency response coordination visualization showing rapid communication and response paths",
      "Calendar view of optimized care schedules with AI-suggested improvements",
      "Family communication hub showing multiple participants and care coordination",
      "Gentle, reassuring visualization of AI oversight maintaining dignity in care situations"
    ],
    historical_narrative: [
      "Founded in 2023 to address the growing complexity of family care coordination",
      "Launched Nanny platform with AI-powered caregiver management capabilities",
      "Established partnerships with leading care service providers",
      "Expanded platform to support both child and elderly care coordination"
    ],
    mission: [
      "To simplify family care management through intelligent technology solutions",
      "To enhance the quality of care through comprehensive coordination and oversight",
      "To provide peace of mind for families managing multiple care responsibilities",
      "To maintain dignity and independence for care recipients through intelligent support systems"
    ]
  },
  theme: {
    primary: "#4A90E2",
    secondary: "#2C3E50",
    accent: "#E67E22",
    background: "#F5F8FA",
    text: "#333333"
  },
  navigation: {
    showTechnology: true,
    showSolutions: true,
    showBlog: false,
    showNews: true,
    showHelp: true,
    showAuth: true
  },
  footerLinks: [
    { label: "About Us", href: "/about" },
    { label: "Services", href: "/services" },
    { label: "Contact", href: "/contact" },
    { label: "Privacy Policy", href: "/privacy" },
    { label: "Terms of Service", href: "/terms" }
  ],
  aboutPage: {
    title: "Revolutionizing Family Care Management",
    subtitle: "AI-Powered Solutions for Modern Families",
    mission: {
      title: "Our Mission",
      content: "To simplify family care management through intelligent technology solutions while maintaining dignity and independence for care recipients."
    },
    team: [],
    values: [
      {
        title: "Family Well-being",
        description: "We put families first in everything we do"
      },
      {
        title: "Care Excellence",
        description: "We strive for the highest standards in care coordination"
      },
      {
        title: "Technological Innovation",
        description: "We leverage cutting-edge AI to enhance caregiving"
      },
      {
        title: "Service Integration",
        description: "We believe in seamless coordination of all care services"
      }
    ]
  },
  homePage: {
    hero: {
      title: "Intelligent Family Care Coordination",
      highlight: "AI-Powered Caregiving Solutions",
      description: "Simplifying family care management through innovative technology and comprehensive service integration."
    },
    config: {
      showPromoVideo: true,
      showContactForm: true
    }
  }
};

export default GKFConfig;
