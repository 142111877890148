import CTIOConfig from "./config/CTIO.ts";
import DASConfig from "./config/DAS.ts";
import DTMConfig from "./config/DTM.ts";
import EAIConfig from "./config/EAI.ts";
import GDOConfig from "./config/GDO.ts";
import GKFConfig from "./config/GKF.ts";
import MDAMConfig from "./config/MDAM.ts";
import NKConfig from "./config/NK.ts";
import SIIConfig from "./config/SII.ts";
import SyncSessionConfig from "./config/SyncSession.ts";
import ZSConfig from "./config/ZS.ts";
import { siteLogos } from "./logos.tsx";

// Logo mapping is now imported from logos.tsx

export type OfferingType = "product" | "service" | "project";

export interface Offering {
  type: OfferingType;
  name: string;
  description: string;
  features: string[];
  benefits: string[];
  value_prop?: string[];
}

export interface Partnership {
  company: string;
  role: string;
}

export interface CoreCompetency {
  name: string;
  areas: string[];
}

export interface InnovationArea {
  name: string;
  focus: string[];
}

export interface Technology {
  core_competencies: CoreCompetency[];
  development_stack: {
    frontend: string[];
    backend: string[];
    infrastructure: string[];
    blockchain?: string[];
    security?: string[];
  };
  innovation_areas: InnovationArea[];
}

export interface TeamMember {
  name: string;
  role: string;
  background: string;
}

export interface Contact {
  email?: string;
  phone?: string;
}

export interface NewsArticle {
  title: string;
  date: string;
  category?: string;
  summary: string;
  image?: string;
  link?: string;
}

export interface CompanyDetails {
  name: string;
  tagline: string;
  description: string;
  details: string;
  offerings: Offering[];
  values: string[];
  beliefs: string[];
  norms: string[];
  image_prompts?: string[];
  partnerships?: Partnership[];
  homepage_video_url?: string;
  historical_narrative?: string[];
  mission?: string[];
  technology?: Technology;
  team?: TeamMember[];
  contact?: Contact;
}

export interface SiteConfig {
  company: CompanyDetails;
  theme: {
    light?: {
      primary: string;
      secondary: string;
      accent: string;
      background: string;
      text: string;
    };
    dark?: {
      primary: string;
      secondary: string;
      accent: string;
      background: string;
      text: string;
    };
    // Keep the flat structure for backward compatibility
    primary: string;
    secondary: string;
    accent: string;
    background: string;
    text: string;
  };
  logo?: {
    svg?: string;
  };
  navigation?: {
    portfolioLabel?: string;
    showTechnology?: boolean;
    showSolutions?: boolean;
    showBlog?: boolean;
    showNews?: boolean;
    showHelp?: boolean;
    showAuth?: boolean;
  };
  dataPaths?: {
    blog?: string;
    news?: string;
  };
  footerLinks: {
    label: string;
    href: string;
  }[];
  aboutPage: {
    title: string;
    subtitle: string;
    mission: {
      title: string;
      content: string;
    };
    team: TeamMember[];
    values: {
      title: string;
      description: string;
    }[];
  };
  portfolioPage?: {
    title: string;
    subtitle: string;
    companies: {
      name: string;
      description: string;
      status: string;
      year: number;
    }[];
    investmentCriteria: {
      title: string;
      description: string;
    }[];
  };
  solutionsPage?: {
    title?: string;
    subtitle?: string;
    content?: string;
    markdown?: string;
  };
  technologyPage?: {
    title?: string;
    subtitle?: string;
    markdown?: string;
  };
  newsPage?: {
    title?: string;
    subtitle?: string;
    articles: NewsArticle[];
  };
  homePage?: {
    hero?: {
      title?: string;
      highlight?: string;
      description?: string;
    };
    config?: {
      showPromoVideo?: boolean;
      showContactForm?: boolean;
    };
  };
  media?: {
    promoVideo?: string;
    promoVideoPoster?: string;
  };
}

// Default navigation configuration
const DEFAULT_NAV_CONFIG = {
  portfolioLabel: "Portfolio",
  showTechnology: true,
  showSolutions: true,
  showBlog: true,
  showNews: true,
  showHelp: true,
  showAuth: true,
};


let currentSite: string | undefined;

export function initializeSite(siteName: string) {
  currentSite = siteName;
}

export function getSiteConfig(overrideSite?: string): SiteConfig {
  // First try to use the override if provided
  let site = overrideSite || currentSite;
  
  // Then try to get from window if available
  if (!site && typeof window !== 'undefined') {
    site = window.SITE_NAME;
  }

  // Finally, try to get from environment variable
  if (!site && typeof Deno !== 'undefined') {
    site = Deno.env.get("CURRENT_SITE");
  }

  if (!site) {
    throw new Error("Site not initialized. Call initializeSite first or ensure window.SITE_NAME is set.");
  }

  console.log(`Getting config for site: ${site}`);

  let config: SiteConfig;
  switch (site) {
    case 'CTIO':
      config = CTIOConfig;
      break;
    case 'DAS':
      config = DASConfig;
      break;
    case 'DTM':
      config = DTMConfig;
      break;
    case 'EAI':
      config = EAIConfig;
      // Override data paths specifically for EAI
      config.dataPaths = {
        ...config.dataPaths,
        blog: "/data/eai/blog",
        news: "/data/eai/news",
        videoScript: "/data/eai/videoscript.raw"
      };
      break;
    case 'GDO':
      config = GDOConfig;
      // Override the theme with green color palette for GDO
      config.theme = {
        // Keep existing theme properties
        ...config.theme,
        // Light mode green theme
        light: {
          primary: "#10B981",    // Emerald-500
          secondary: "#065F46",  // Emerald-800
          accent: "#34D399",     // Emerald-400
          background: "#ECFDF5", // Emerald-50
          text: "#064E3B",       // Emerald-900
        },
        // Dark mode green theme
        dark: {
          primary: "#34D399",    // Emerald-400
          secondary: "#059669",  // Emerald-600
          accent: "#6EE7B7",     // Emerald-300
          background: "#064E3B", // Emerald-900
          text: "#D1FAE5",       // Emerald-100
        },
        // Update flat structure for backward compatibility
        primary: "#10B981",      // Emerald-500
        secondary: "#065F46",    // Emerald-800
        accent: "#34D399",       // Emerald-400
        background: "#ECFDF5",   // Emerald-50
        text: "#064E3B",         // Emerald-900
      };
      break;
    case 'GKF':
      config = GKFConfig;
      break;
    case 'MDAM':
      config = MDAMConfig;
      break;
    case 'NK':
      config = NKConfig;
      break;
    case 'SII':
      config = SIIConfig;
      break;
    case 'SyncSession':
      config = SyncSessionConfig;
      break;
    case 'ZS':
      config = ZSConfig;
      break;
    default:
      throw new Error(`Unknown site: ${site}`);
  }

  // Merge navigation config with defaults
  config.navigation = {
    ...DEFAULT_NAV_CONFIG,
    ...config.navigation
  };

  // Add custom logo if available for this site
  if (siteLogos[site]) {
    config.logo = {
      ...config.logo,
      svg: siteLogos[site]  // This is now a JSX element, not a string
    };
  }


  return config;
}
