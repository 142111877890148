import { SiteConfig } from "../site.tsx";

const SIIConfig: SiteConfig = {
  company: {
    name: "Sightline Innovation Inc.",
    tagline: "Canada's first AI company is now Canada's first company run by AI",
    description: "Canada's first AI company, now pioneering AI-driven corporate operations.\nAutonomous systems manage strategic patent portfolio and governance.\nDemonstrating the future of AI-operated intellectual property management.\nShowcasing AI-human collaboration in corporate decision-making.",
    details: "Founded in 2012, Sightline Innovation Inc. established itself as one of Canada's earliest AI pioneers. Today, Sightline embarks on a groundbreaking new chapter: becoming Canada's first company operated primarily by artificial intelligence. Our autonomous systems now manage the company's strategic patent portfolio and core corporate functions, demonstrating a revolutionary model for business operations.\n\nThis transition leverages Sightline's own foundational patents in human-in-the-loop AI. We've implemented a sophisticated AI governance framework that autonomously handles patent strategy, identifies licensing opportunities, manages partnerships, and oversees operational decisions. This system operates with unparalleled efficiency, analyzing vast datasets to optimize our intellectual property's value and market position.\n\nOur AI management system continuously scans global technology trends, patent landscapes, and market dynamics to inform its strategic directives. It proactively identifies potential licensees, evaluates partnership synergies, and adapts the company's focus to maximize the impact and relevance of our patent portfolio in emerging technology sectors.\n\nSightline Innovation serves as a living laboratory, showcasing the practical application of AI in corporate governance and IP management. Our human team collaborates with the AI, providing oversight, setting high-level objectives, and intervening when necessary, defining a new paradigm for AI-human partnership in the enterprise. We are not just managing patents; we are demonstrating the future of business itself.",
    historical_narrative: [
      "Founded in 2012 as one of Canada's first AI startups",
      "Developed foundational patents in machine learning and human-in-the-loop AI",
      "Established a strategic patent portfolio focused on AI, IoT, and data processing",
      "Transitioned to an IP holding company model, focusing on patent value",
      "Initiated development of AI-driven management systems in 2022",
      "Achieved operational status as Canada's first AI-run company in 2024",
      "Pioneering autonomous corporate governance and IP management"
    ],
    mission: [
      "To demonstrate the power and efficiency of AI-driven corporate operations",
      "To maximize intellectual property value through autonomous AI management",
      "To define the future of AI-human collaboration in strategic decision-making",
      "To lead the responsible implementation of AI in corporate governance"
    ],
    offerings: [
      {
        type: "product",
        name: "AI-Managed Patent Portfolio",
        description: "A strategic collection of AI and machine learning patents, autonomously managed for optimal value and relevance.",
        features: [
          "AI-driven strategic optimization and valuation",
          "Autonomous identification of licensing and partnership opportunities",
          "Continuous analysis of technology trends and market fit",
          "Automated portfolio administration and reporting"
        ],
        benefits: [
          "Dynamically optimized patent value",
          "Proactive market positioning and opportunity capture",
          "Reduced overhead and increased efficiency in IP management",
          "Access to a portfolio aligned with future technology needs"
        ],
        value_prop: [
          "Leverage an IP portfolio continuously optimized by AI for maximum strategic impact",
          "Benefit from AI-identified implementation and licensing opportunities",
          "Partner with a company demonstrating the cutting edge of AI-driven business",
          "Gain insights from the operations of the world's first AI-run patent company"
        ]
      },
      {
        type: "service",
        name: "AI-Powered IP Strategy & Licensing",
        description: "Licensing and strategic partnership opportunities derived from our AI-managed patent portfolio.",
        features: [
          "Licensing terms optimized by AI based on market data",
          "AI-driven analysis of potential partnership synergies",
          "Implementation guidance informed by AI predictive analytics",
          "Continuous monitoring and adaptation of licensing strategies"
        ],
        benefits: [
          "Access to strategically relevant IP under optimized terms",
          "Data-driven insights for successful implementation",
          "Partnerships identified for maximum mutual benefit",
          "Experience the efficiency of AI-managed IP transactions"
        ],
        value_prop: [
          "Engage with an AI-driven licensing program that adapts to real-time market conditions",
          "Receive strategic implementation insights derived from AI analysis",
          "Benefit from licensing arrangements continuously optimized for value",
          "Witness firsthand the future of AI-managed intellectual property"
        ]
      }
    ],
    values: [
      "Autonomous Efficiency",
      "Pioneering Innovation",
      "AI-Human Synergy",
      "Strategic Foresight"
    ],
    beliefs: [
      "AI is capable of effective and strategic corporate management",
      "The optimal future involves collaboration between humans and AI systems",
      "Autonomous systems can unlock significant value in intellectual property",
      "Demonstrating practical AI implementation accelerates responsible adoption"
    ],
    norms: [
      "AI-first decision making, human-verified",
      "Continuous learning and adaptation of AI systems",
      "Transparency in AI operations and governance",
      "Rigorous testing and validation of AI-driven strategies"
    ],
    image_prompts: [
      "Futuristic command center where AI interfaces display complex patent data and strategic decisions, observed by a small, focused human team",
      "Abstract visualization of AI neural networks analyzing global patent data, identifying connections and opportunities represented by glowing nodes",
      "Clean, modern graphic showing an AI core processing information streams (market trends, legal data, tech news) to output optimized IP strategies",
      "Conceptual image of a company structure where the central 'brain' is an AI, with human departments collaborating through digital interfaces",
      "Illustration of an AI presenting strategic recommendations on a holographic display to human executives in a minimalist boardroom",
      "Side-by-side comparison: a traditional cluttered office vs. a sleek, automated office run by AI, highlighting efficiency",
      "Dynamic visualization of the AI managing the patent portfolio, showing patents as assets being actively analyzed, licensed, and protected",
      "Symbolic image of a robotic hand and a human hand working together on a complex digital interface representing corporate governance",
      "Infographic style visualization showing the AI's decision-making process for patent licensing or acquisition",
      "A serene, high-tech environment symbolizing the efficiency and order brought by AI management to complex corporate tasks"
    ],
    // Note: 'merge' field from YAML is ignored as it's not part of the SiteConfig schema
    partnerships: [], // Assuming no partnerships defined in the YAML source
    homepage_video_url: undefined, // Assuming no homepage_video_url defined in the YAML source
    technology: undefined, // Assuming no technology defined in the YAML source
    team: undefined, // Assuming no team defined in the YAML source
    contact: undefined // Assuming no contact defined in the YAML source
  },
  theme: {
    primary: "#2C3E50", // Keeping existing theme, update if needed
    secondary: "#34495E",
    accent: "#3498DB",
    background: "#ECF0F1",
    text: "#2C3E50"
  },
  navigation: {
    showTechnology: true,
    showSolutions: true,
    showBlog: false,
    showNews: true,
    showHelp: false,
    showAuth: false
  },
  footerLinks: [
    {
      label: "Patents",
      href: "/patents"
    },
    {
      label: "Licensing",
      href: "/licensing"
    },
    {
      label: "Contact",
      href: "/contact"
    },
    {
      label: "Privacy Policy",
      href: "/privacy"
    }
  ],
  aboutPage: {
    title: "About Sightline Innovation",
    subtitle: "Canada's First AI-Run Company",
    mission: {
      title: "Our Mission",
      content: "To demonstrate the viability and advantages of AI-run corporate management while optimizing intellectual property value through AI-driven patent strategy."
    },
    values: [
      {
        title: "AI-Human Collaboration",
        description: "Creating optimal outcomes through the partnership of human expertise and AI capabilities"
      },
      {
        title: "Technological Innovation",
        description: "Pushing the boundaries of AI-driven corporate management and intellectual property administration"
      },
      {
        title: "Operational Excellence",
        description: "Achieving unprecedented efficiency and insight through AI-powered operations"
      },
      {
        title: "Pioneering Leadership",
        description: "Leading the way in demonstrating how AI can effectively manage corporate assets"
      }
    ],
    team: []
  },
  technologyPage: {
    title: "Our AI Management System",
    subtitle: "Revolutionary AI-Driven Corporate Governance",
    markdown: "# AI-Powered Management\n\nOur revolutionary AI management system oversees all aspects of our operations:\n\n- Patent strategy optimization\n- Licensing opportunity identification\n- Technology trend analysis\n- Portfolio management\n- Strategic decision-making"
  },
  solutionsPage: {
    title: "AI-Driven Licensing Solutions",
    subtitle: "Strategic IP Implementation",
    content: "Experience the future of intellectual property management through our AI-optimized licensing programs and patent portfolio."
  },
  homePage: {
    hero: {
      title: "Canada's First AI-Run Company",
      highlight: "Pioneering AI-Driven Corporate Management",
      description: "Demonstrating the future of business through autonomous systems that manage our strategic patent portfolio with unprecedented efficiency and insight."
    },
    config: {
      showPromoVideo: true,
      showContactForm: true
    }
  }
};

export default SIIConfig;
