import { SiteConfig } from "../site.tsx";

const NKConfig: SiteConfig = {
  company: {
    name: "NowKast",
    tagline: "Revolutionizing social media through decentralized networks and ethical engagement incentives.",
    description: "Pioneer in mesh-networked social media platforms. Leveraging edge computing for cost-effective content delivery. Rewarding authentic engagement and consensus building. Creating sustainable, non-predatory social networks.",
    details: "NowKast is reimagining social media through an innovative platform that leverages mesh networking and edge computing to dramatically reduce infrastructure costs, enabling a social network that prioritizes user value over predatory monetization. By distributing content delivery across user devices and local edge nodes, NowKast creates a more efficient and cost-effective platform that can focus on fostering genuine connection and dialogue.\n\nOur revolutionary reward system incentivizes constructive behavior, open-mindedness, and consensus-building rather than divisive engagement. Through sophisticated algorithms that measure the quality of interactions, fact-checking contributions, and bridge-building between different viewpoints, users earn recognition and rewards for fostering understanding and truth rather than conflict and sensationalism.\n\nThe platform's distributed architecture not only reduces costs but also enhances content delivery speed and reliability. By utilizing mesh networking technology, NowKast creates resilient local content networks that continue functioning even during connectivity disruptions. This approach also enables more efficient content distribution, reducing bandwidth costs while improving user experience.\n\nNowKast's commitment to truth and consensus is embedded in its core features, including collaborative fact-checking mechanisms, bridge-building rewards for users who facilitate understanding across different viewpoints, and transparency metrics that highlight both the diversity and quality of user interactions. This creates a social platform where truth and understanding naturally rise above misinformation and division.",
    offerings: [
      {
        type: "product",
        name: "NowKast Platform",
        description: "Mesh-networked social media platform with ethical engagement incentives",
        features: [
          "Distributed content delivery",
          "Edge computing integration",
          "Consensus-building rewards",
          "Truth verification system"
        ],
        benefits: [
          "Reduced infrastructure costs",
          "Improved content delivery",
          "Authentic engagement",
          "Community-driven truth"
        ],
        value_prop: [
          "Create meaningful connections without algorithmic manipulation",
          "Reduce operational costs through distributed architecture",
          "Foster truth and understanding through incentivized collaboration",
          "Build resilient communities through sustainable engagement"
        ]
      },
      {
        type: "service",
        name: "Community Building Tools",
        description: "Suite of tools for fostering healthy online communities",
        features: [
          "Bridge-building metrics",
          "Collaborative fact-checking",
          "Engagement quality analysis",
          "Consensus tracking"
        ],
        benefits: [
          "Healthier discussions",
          "Reduced polarization",
          "Enhanced understanding",
          "Truth-focused dialogue"
        ],
        value_prop: [
          "Transform online discussions into productive dialogues",
          "Build bridges across different viewpoints and communities",
          "Establish truth through collaborative verification",
          "Create sustainable, healthy online communities"
        ]
      }
    ],
    values: [
      "Truth and Transparency",
      "Open-mindedness",
      "Consensus Building",
      "Sustainable Operations"
    ],
    beliefs: [
      "Social media should unite, not divide",
      "Truth emerges through open dialogue",
      "Technology should serve humanity",
      "Sustainable platforms enable better content"
    ],
    norms: [
      "Truth-seeking behavior",
      "Constructive engagement",
      "Bridge-building dialogue",
      "Community support"
    ],
    image_prompts: [
      "Abstract visualization of a mesh network with interconnected nodes glowing with warm, inviting colors, representing community connections",
      "Modern interface showing consensus-building metrics with bridges forming between different viewpoint clusters",
      "Dynamic 3D representation of edge computing nodes distributing social content across a community network",
      "Professional illustration of truth verification process with collaborative fact-checking visualization",
      "Artistic interpretation of bridge-building rewards showing connections forming across different community groups",
      "Clean, minimal dashboard displaying engagement quality metrics and community health indicators",
      "Isometric visualization of content distribution through mesh network with edge computing nodes",
      "Split-screen showing traditional vs. NowKast social media dynamics, highlighting reduced polarization",
      "Abstract representation of truth emerging through consensus with flowing data streams converging",
      "Modern social media interface emphasizing connection and understanding rather than conflict"
    ],
    historical_narrative: [
      "Founded in 2023 to address the growing crisis of social media polarization",
      "Developed initial mesh networking prototype for content distribution",
      "Launched beta platform with revolutionary consensus-building reward system",
      "Established first community-driven truth verification system",
      "Implemented edge computing infrastructure for cost-effective content delivery"
    ],
    mission: [
      "To create social networks that unite rather than divide communities",
      "To reward authentic engagement and consensus-building over conflict",
      "To establish sustainable, non-predatory platforms for social interaction",
      "To leverage technology for truth and understanding rather than engagement metrics"
    ]
  },
  theme: {
    primary: "#2C3E50",
    secondary: "#3498DB",
    accent: "#E74C3C",
    background: "#ECF0F1",
    text: "#2C3E50"
  },
  navigation: {
    showTechnology: true,
    showSolutions: true,
    showBlog: true,
    showNews: true,
    showHelp: true,
    showAuth: true
  },
  footerLinks: [
    {
      label: "Privacy Policy",
      href: "/privacy"
    },
    {
      label: "Terms of Service",
      href: "/terms"
    },
    {
      label: "Contact",
      href: "/contact"
    }
  ],
  aboutPage: {
    title: "About NowKast",
    subtitle: "Building the Future of Social Media",
    mission: {
      title: "Our Mission",
      content: "To create social networks that unite rather than divide communities, rewarding authentic engagement and consensus-building over conflict."
    },
    team: [],
    values: [
      {
        title: "Truth and Transparency",
        description: "We believe in fostering honest, open dialogue and maintaining complete transparency in our operations."
      },
      {
        title: "Open-mindedness",
        description: "We encourage diverse perspectives and thoughtful consideration of different viewpoints."
      },
      {
        title: "Consensus Building",
        description: "We prioritize finding common ground and building bridges between different communities."
      },
      {
        title: "Sustainable Operations",
        description: "We commit to creating sustainable, non-predatory platforms that serve users first."
      }
    ]
  },
  homePage: {
    hero: {
      title: "Revolutionizing Social Media",
      highlight: "Through Decentralized Networks",
      description: "Join the movement towards ethical, sustainable social networking that brings people together."
    },
    config: {
      showPromoVideo: true,
      showContactForm: true
    }
  },
  technologyPage: {
    title: "Our Technology",
    subtitle: "Innovative Solutions for Better Social Media",
    markdown: "# Revolutionary Platform Architecture\n\nNowKast leverages cutting-edge mesh networking and edge computing to create a more efficient and sustainable social media platform.\n\n## Key Technologies\n\n- Distributed Content Delivery\n- Edge Computing Integration\n- Consensus-Building Algorithms\n- Truth Verification Systems"
  }
};

export default NKConfig;
