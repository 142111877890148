import type { SiteConfig } from "../site.tsx";  //

const EAIConfig: SiteConfig = {
  company: {
    name: "Elbert.AI",
    tagline: "Empowering organizations with sovereign AI operations, workflows, and automation to institutionalize business value.",
    description: "Provider of sovereign AI operations and workflow automation solutions. Specializing in building standard operating procedures and AI agents. Enabling companies to institutionalize their business operations. Delivering complete ownership of enterprise intellectual property.",
    details: "Elbert.AI helps companies build standard operating procedures, workflows, automation, and AI agents to augment and institutionalize their business operations with full sovereignty. In today's world of AI, where knowledge is distilled from one source to the next, having sovereignty over your digital operations as they become purely digital is an existential requirement for all companies.\n\nWith the ongoing changes in the software world, the old business models of expensive consulting firms and high-priced PhDs to build an AI strategy are no longer viable. AI is simply available to everyone and will be the most disruptive force in economic history, requiring a new approach to implementation and integration.\n\nOur process lets you walk away with complete sovereignty and ownership over your enterprise, its intellectual property, and the sustainability to train your own workforce all from internal operations. We focus on building systems that preserve and enhance your organizational knowledge while maintaining full control over your data and processes.\n\nElbert.AI enables you to trim your consulting and vendor costs, optimize your human operations, and institutionalize the value and knowledge in your company for your shareholders and stakeholders. By embedding AI capabilities directly into your operational workflows, we help you create sustainable competitive advantages while maintaining complete ownership of your systems and data.",
    offerings: [
      {
        type: "service",
        name: "AI Operations Framework",
        description: "Comprehensive framework for building sovereign AI operations and workflows",
        features: [
          "Standard operating procedure development",
          "AI agent integration",
          "Workflow automation",
          "Knowledge institutionalization",
          "Internal capability building"
        ],
        benefits: [
          "Complete sovereignty over AI operations",
          "Reduced dependency on external consultants",
          "Optimized human operations",
          "Preserved enterprise intellectual property"
        ],
        value_prop: [
          "Maintain full ownership of your AI operations and intellectual property",
          "Build sustainable internal capabilities that reduce external dependencies",
          "Optimize costs by eliminating expensive consulting engagements",
          "Institutionalize knowledge to create lasting enterprise value"
        ]
      },
      {
        type: "service",
        name: "Business Process Transformation",
        description: "End-to-end transformation of business processes with AI-augmented workflows",
        features: [
          "Process analysis and optimization",
          "AI-augmented workflow design",
          "Implementation and integration",
          "Training and capability building",
          "Continuous improvement framework"
        ],
        benefits: [
          "Streamlined operations",
          "Increased efficiency",
          "Reduced operational costs",
          "Enhanced decision making"
        ],
        value_prop: [
          "Transform business operations with AI while maintaining full control",
          "Create efficient, optimized workflows that reduce operational costs",
          "Build internal capabilities that continue to deliver value",
          "Preserve and enhance your competitive advantages"
        ]
      },
      {
        type: "product",
        name: "Sovereign AI Toolkit",
        description: "Suite of tools for building and managing sovereign AI operations",
        features: [
          "AI agent development framework",
          "Workflow automation tools",
          "Knowledge management system",
          "Performance analytics dashboard",
          "Integration capabilities"
        ],
        benefits: [
          "Accelerated implementation",
          "Standardized approach",
          "Comprehensive visibility",
          "Sustainable operations"
        ],
        value_prop: [
          "Rapidly deploy sovereign AI capabilities with proven tools",
          "Maintain consistent quality across all AI implementations",
          "Monitor and optimize performance with comprehensive analytics",
          "Ensure long-term sustainability of AI operations"
        ]
      }
    ],
    values: [
      "Sovereignty",
      "Knowledge Preservation",
      "Operational Excellence",
      "Sustainable Value"
    ],
    beliefs: [
      "AI sovereignty is an existential requirement for modern businesses",
      "Internal capabilities deliver more sustainable value than external consultants",
      "Knowledge institutionalization creates lasting enterprise value",
      "AI should augment and optimize human operations, not replace them"
    ],
    norms: [
      "Client sovereignty first",
      "Knowledge transfer and capability building",
      "Practical, implementable solutions",
      "Long-term value creation"
    ],
    historical_narrative: [
      "Founded in 2025 to address the need for sovereign AI operations",
      "Developed proprietary methodology for institutionalizing business operations with AI",
      "Established framework for building sustainable internal AI capabilities",
      "Pioneered approach to reducing dependency on external AI consultants"
    ],
    mission: [
      "To empower organizations with sovereign control over their AI operations",
      "To help companies institutionalize their business knowledge through AI",
      "To reduce dependency on external consultants through internal capability building",
      "To preserve and enhance enterprise value through AI-augmented operations"
    ],
    technology: {
      core_competencies: [
        {
          name: "AI Operations",
          areas: [
            "Standard Operating Procedures",
            "Workflow Automation",
            "AI Agent Development",
            "Process Optimization",
            "Knowledge Management Systems",
            "Operational Intelligence"
          ]
        },
        {
          name: "Business Sovereignty",
          areas: [
            "IP Ownership Frameworks",
            "Knowledge Institutionalization",
            "Internal Capability Building",
            "Vendor Independence",
            "Sustainable AI Operations",
            "Self-sufficient AI Training"
          ]
        },
        {
          name: "Operational Efficiency",
          areas: [
            "Human-AI Collaboration",
            "Cost Optimization",
            "Process Streamlining",
            "Resource Allocation",
            "Performance Analytics",
            "Continuous Improvement"
          ]
        }
      ],
      development_stack: {
        frontend: [
          "React",
          "TypeScript",
          "Tailwind CSS",
          "D3.js"
        ],
        backend: [
          "Python",
          "Node.js",
          "FastAPI",
          "PostgreSQL"
        ],
        infrastructure: [
          "Docker",
          "Kubernetes",
          "Private Cloud",
          "On-premise Solutions"
        ],
        security: [
          "Zero-Trust Architecture",
          "Data Encryption",
          "Access Control",
          "Audit Logging"
        ]
      },
      innovation_areas: [
        {
          name: "Sovereign AI Operations",
          focus: [
            "Self-contained AI Systems",
            "Internal Knowledge Preservation",
            "Operational Autonomy",
            "Vendor Independence"
          ]
        },
        {
          name: "Business Process Automation",
          focus: [
            "Workflow Optimization",
            "Standard Operating Procedures",
            "Decision Support Systems",
            "Knowledge Capture"
          ]
        },
        {
          name: "Enterprise Value Protection",
          focus: [
            "IP Ownership",
            "Knowledge Institutionalization",
            "Sustainable Operations",
            "Stakeholder Value Creation"
          ]
        }
      ]
    },
    image_prompts: [
      "Professional visualization of a company building its own AI operations center with employees working on digital workflows and AI systems, emphasizing ownership and control",
      "Abstract representation of knowledge institutionalization showing business processes being captured, organized, and enhanced through AI systems",
      "Modern office setting with teams collaborating on standard operating procedures using advanced digital interfaces and AI assistants",
      "Conceptual illustration of sovereignty in AI operations with clear boundaries and ownership indicators around digital assets and workflows",
      "Clean, corporate visualization of cost optimization showing the transition from external consultants to internal capabilities with downward trending cost graphs",
      "Isometric illustration of a complete AI operations ecosystem with workflow automation, knowledge management, and decision support components",
      "Professional team implementing AI-augmented workflows with clear indicators of knowledge transfer and capability building",
      "Abstract representation of enterprise value preservation showing intellectual property and operational knowledge being secured within organizational boundaries",
      "Digital transformation scene with AI agents being integrated into business processes while maintaining human oversight and control",
      "Technical illustration of the sovereign AI toolkit components working together to create a self-sufficient operational environment"
    ]
  },
  theme: {
    primary: "#4A6FFF",
    secondary: "#2A3F7F",
    accent: "#00D084",
    background: "#FFFFFF",
    text: "#333333"
  },
  navigation: {
    portfolioLabel: "Products",
    showTechnology: true,
    showSolutions: true,
    showBlog: true,
    showNews: true,
    showHelp: true,
    showAuth: true
  },
  footerLinks: [
    { label: "Privacy Policy", href: "/privacy" },
    { label: "Terms of Service", href: "/terms" },
    { label: "Contact", href: "/contact" }
  ],
  aboutPage: {
    title: "About Elbert.AI",
    subtitle: "Building Sovereign AI Operations for Business Value",
    mission: {
      title: "Our Mission",
      content: "To empower organizations with sovereign control over their AI operations, helping companies institutionalize their business knowledge through AI while reducing dependency on external consultants."
    },
    team: [],
    values: [
      { title: "Sovereignty", description: "Ensuring complete control over AI operations and data" },
      { title: "Knowledge Preservation", description: "Institutionalizing organizational knowledge for lasting value" },
      { title: "Operational Excellence", description: "Optimizing business processes through AI-augmented workflows" },
      { title: "Sustainable Value", description: "Creating enduring enterprise value through internal capabilities" }
    ]
  },
  media: {
    promoVideo: "https://storage.googleapis.com/public-das-web-assets/eai-intro.small.mp4"
  },
  portfolioPage: {
    title: "Our Solutions",
    subtitle: "Sovereign AI Operations for Enterprise Value",
    companies: [],
    investmentCriteria: []
  },
  homePage: {
    hero: {
      title: "Sovereign AI Operations",
      highlight: "Institutionalize Your Business Value",
      description: "Empowering organizations with sovereign AI operations, workflows, and automation to institutionalize business value and reduce dependency on external consultants."
    },
    config: {
      showPromoVideo: true,
      showContactForm: true
    }
  }
};

export default EAIConfig;
